@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
script + iframe {
  display: none !important;
}
.Sara {
  font-family: "Montserrat";
}
.bg-blue {
  background-color: #061b46;
}
.DivA {
  background-image: url(./Imgs/images/background.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.DivB {
  background-image: url(./Imgs/images/Bg2.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.DivC {
  background-color: #066a84;
}
.Div4 {
  background-image: url(./Imgs/images/Bg2.jpg);
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}
.RD {
  background-color: #066a84;
}
.TextB {
  font-size: 1.2rem;
}
.TopImg {
  border-radius: 100%;
}
.ConnBtn {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  font-size: 30px;
  border-radius: 50px;
  padding: 5px 40px;
}
.RDbtn {
  background-color: transparent;
  border: 3px solid white;
  color: white;
}
.RDbtn:hover {
  color: #e3e3e3 !important;
}
.BtnDiv {
  background-color: #061b46;
  /* max-width: 400px; */
  margin: auto;
  padding: 20px;
  border-radius: 10px;
}
.connectbtn {
  background-color: #066a84;
  border: none;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
  box-shadow: 5px 5px 0 0 black;
}
.Div4 img {
  width: 400px;
  max-width: 100%;
}
.ftrimg,
.DivB img,
.DivC img {
  width: 400px;
  max-width: 100%;
}
.Ftr a img {
  width: 60px !important;
}
.userdoges input {
  display: none;
}
.userdoges a {
  background-color: grey;
  padding: 5px 0;
  color: black !important;
  border-radius: 5px;
  border: 1px solid black;
  box-shadow: 5px 5px 0 0 black;
  cursor: pointer;
  display: block;
  width: 100%;
  padding-top: 0 !important;
}
.userdoges input:checked + a {
  box-shadow: none;
  top: 5px;
  left: 5px;
  position: relative;
  background: #066a84;
}
.userdoges label {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}
.info-icon {
  float: right;
  padding: 10px;
  background-color: #061b46aa;
  border-radius: 100%;
  font-size: 10px;
  width: 25px;
  height: 25px;
  line-height: 5px;
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0006;
}
.loading img {
  width: 350px;
}
.userdoges {
  background-color: #061b46;
  border-radius: 20px;
}

/* -----------------Twitter Page--------------- */

.SaraTwi {
  background-color: #066a84;
}
